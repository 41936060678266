function getBaseURL() {
  const { location } = window;
  const backendPort = process.env.VUE_APP_BACKEND_PORT || location.port;
  const baseURL = `${location.protocol}//${location.hostname}${backendPort ? `:${backendPort}` : ''}/vaccine`;
  return baseURL;
}

function getClient() {
  let client = '';
  const host = window.location.host.split('.');
  if (host.length > 1) {
    [client] = host;
  }
  return client;
}

function isHepClient() {
  const hepClients = (process.env.VUE_APP_HEP_CLIENTS || '').split(',');

  return hepClients.includes(getClient());
}

function isHepProgramClient() {
  const hepClients = (process.env.VUE_APP_HEP_PROGRAM || '').split(',');

  return hepClients.includes(getClient());
}

function getDashboardURL() {
  const { location } = window;
  const domain = process.env.VUE_APP_DASHBOARD_DOMAIN || 'dashboard.getzedic.com';
  const dashboardURL = `${location.protocol}//${getClient()}.${domain}`;
  return dashboardURL;
}

export default {
  getBaseURL,
  getClient,
  isHepClient,
  isHepProgramClient,
  getDashboardURL,
};
