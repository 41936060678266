<template>
  <div class="page-block">
    <div class="mailconfirm wf-section page-block-item">
      <div class="herocontainer">
        <img src="@/assets/images/envelope_2709-fe0f.png" alt="" class="mailemoji">
        <p class="page-title">{{ getTitleText() }}</p>
        <p class="general-text">
          {{ getMainText() }}
          <br>
          <br>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from '@/utils/utils';

const CLIENT = Utils.getClient();

export default {
  name: 'LoginEmailPage',

  methods: {
    getTitleText() {
      if (CLIENT === 'pappas'
          || CLIENT === 'lazydog'
          || CLIENT === 'firstwatch'
          || CLIENT === 'citybbq'
          || CLIENT === 'tendergreens'
          || CLIENT === 'blackbeardiner'
          || CLIENT === 'caspers'
          || CLIENT === 'daveandbusters'
          || CLIENT === 'chwinery'
          || CLIENT === 'rubios'
          || CLIENT === 'brinker'
          || CLIENT === 'flynnrg'
          || CLIENT === 'cameronmitchell'
          || CLIENT === 'thekitchen'
          || CLIENT === 'biscuitville'
          || CLIENT === 'callaway'
          || CLIENT === 'murata'
          || CLIENT === 'loumalnatis') {
        return 'Please check your email!';
      }
      if (CLIENT === 'gei') {
        return 'Please check your GEI Consultants, Inc. email!';
      }
      return 'Please check the store email!';
    },

    getMainText() {
      if (CLIENT === 'pappas'
          || CLIENT === 'lazydog'
          || CLIENT === 'firstwatch'
          || CLIENT === 'citybbq'
          || CLIENT === 'tendergreens'
          || CLIENT === 'blackbeardiner'
          || CLIENT === 'caspers'
          || CLIENT === 'daveandbusters'
          || CLIENT === 'chwinery'
          || CLIENT === 'rubios'
          || CLIENT === 'brinker'
          || CLIENT === 'flynnrg'
          || CLIENT === 'cameronmitchell'
          || CLIENT === 'thekitchen'
          || CLIENT === 'biscuitville'
          || CLIENT === 'callaway'
          || CLIENT === 'murata'
          || CLIENT === 'loumalnatis') {
        return 'We\'ve sent a secure link to your email that you have on file with Zedic. Check your email and click the link to access your dashboard.';
      }
      if (CLIENT === 'gei') {
        return 'We\'ve sent a secure link to the GEI Consultants, Inc. email that you have on file with Zedic. Check your email and click the link to access COVID-19 and test tracking portal.';
      }
      return 'We just sent a link to the store email inbox so that we know you have access to this email. Check your email and click the link to access your dashboard.';
    },
  },
};
</script>

<style lang="scss" scoped>

.mailconfirm {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  text-align: center;
}

</style>
